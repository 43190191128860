import React from "react"
import ReactDOM from "react-dom/client"
import "./contact.css"

function Contact(){
  const message = "Speak with Wanderlei Oliveira";
  return (
    <div className="router-provider contact">
      <ul>
        <li id="ul-number">
          <img width={50} height={50} src="https://cdn-icons-png.freepik.com/256/455/455705.png?semt=ais_hybrid"></img>
          <a href="tel:+17746064161">+1 (774)606-4161</a> </li>
        <li>
          <img width={50} height={50} src="https://static-00.iconduck.com/assets.00/email-circle-fill-icon-512x512-vs56hqq6.png"></img>
          <a href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@vipconstructionirrigation.net" target="_blank">contact@vipconstructionirrigation.net</a></li>
        <li id="ul-socials">
          <ul>
            <li>
              <img className="page-icon" src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/600px-Instagram_icon.png"> 
              </img>
              <a target="_blank" href="https://www.instagram.com/vipconstructionirrigation/">
              @vipconstructionirrigation
              </a>
            </li>
            <li className="socials-next">
              <img className="page-icon" src="https://upload.wikimedia.org/wikipedia/en/thumb/0/04/Facebook_f_logo_%282021%29.svg/1200px-Facebook_f_logo_%282021%29.svg.png"> 
              </img>
              <a target="_blank" href="https://m.facebook.com/100069560324072/">Vip Construction & Irrigation</a>
            </li>
          </ul>
        </li>
        <li id="message">
          {message}
        </li>
        <li>
          <a>We accept Cash and Card</a>
        </li>
      </ul>
    </div>
  )
}

export default Contact;
