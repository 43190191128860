import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom/client"
import "./aboutus.css"
import ImageGallery from "react-image-gallery";
import Rating from "react-rating";

import landscapeImg from "./resources/landscape.png"
import stonewallsImg from "./resources/stone_walls.png"
import walkwaysImg from "./resources/walkways.png"
import img1 from "./resources/1.jpg"
import img2 from "./resources/2.jpg"
import img3 from "./resources/3.jpg"
import img4 from "./resources/4.jpg"
import img5 from "./resources/5.jpg"
import img6 from "./resources/6.jpg"
import img7 from "./resources/7.jpg"
import img8 from "./resources/8.jpg"
import img9 from "./resources/9.jpg"
import img10 from "./resources/10.jpg"
import img11 from "./resources/11.jpg"
import img12 from "./resources/12.jpg"
import img13 from "./resources/13.jpg"
import img14 from "./resources/14.jpg"
import img15 from "./resources/15.jpg"
import img16 from "./resources/16.jpg"
import img17 from "./resources/17.jpg"
import img18 from "./resources/18.jpg"
import img19 from "./resources/19.jpg"
import img20 from "./resources/20.jpg"
import img21 from "./resources/21.jpg"
import img22 from "./resources/22.jpg"
import img23 from "./resources/23.jpg"
import img24 from "./resources/24.jpg"
import img25 from "./resources/25.jpg"
import img26 from "./resources/26.jpg"
import img27 from "./resources/27.jpg"
import img28 from "./resources/28.jpg"
import img29 from "./resources/29.jpg"
import img30 from "./resources/30.jpg"

function AboutUs(){
  
  const [message, setMessage] = useState('');
  const [rating, setRating] = useState(3);
  const [Reviews, setReviews] = useState([]);
  let reviews = []

  useEffect(() => {
    const fetchData = async () => {
      let response = await fetch("/.netlify/functions/listreviews")
      let reviews = await response.json()
      setReviews(reviews)
    }
    fetchData()
    console.log(Reviews)
  }, []);

  const handleRating = (value) => {
    console.log(value);
    setRating(value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleClick = async () => {
    console.log("Button clicked!");
    let response = await fetch("/.netlify/functions/addreview", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        stars: rating,
        message: message
      })
    })
    let newReview = await response.json()
    setReviews([...Reviews, newReview]);
    console.log("Current reviews: " + reviews);
  };


  
  const images = [
    {
      original: landscapeImg,
      thumbnail: ""
    },
    {
      original: stonewallsImg,
      thumbnail: ""
    },
    {
      original: walkwaysImg,
      thumbnail: ""
    },
    {
      original: img1,
      thumbnail: ""
    },
    {
      original: img2,
      thumbnail: ""
    },
    {
      original: img3,
      thumbnail: ""
    },
    {
      original: img4,
      thumbnail: ""
    },
    {
      original: img5,
      thumbnail: ""
    },
    {
      original: img6,
      thumbnail: ""
    },
    {
      original: img7,
      thumbnail: ""
    },
    {
      original: img8,
      thumbnail: ""
    },
    {
      original: img9,
      thumbnail: ""
    },
    {
      original: img10,
      thumbnail: ""
    },
    {
      original: img11,
      thumbnail: ""
    },
    {
      original: img12,
      thumbnail: ""
    },
    {
      original: img13,
      thumbnail: ""
    },
    {
      original: img14,
      thumbnail: ""
    },
    {
      original: img15,
      thumbnail: ""
    },
    {
      original: img16,
      thumbnail: ""
    },
    {
      original: img17,
      thumbnail: ""
    },
    {
      original: img18,
      thumbnail: ""
    },
    {
      original: img19,
      thumbnail: ""
    },
    {
      original: img20,
      thumbnail: ""
    },
    {
      original: img21,
      thumbnail: ""
    },
    {
      original: img22,
      thumbnail: ""
    },
    {
      original: img23,
      thumbnail: ""
    },
    {
      original: img24,
      thumbnail: ""
    },
    {
      original: img25,
      thumbnail: ""
    },
    {
      original: img26,
      thumbnail: ""
    },
    {
      original: img27,
      thumbnail: ""
    },
    {
      original: img28,
      thumbnail: ""
    },
    {
      original: img29,
      thumbnail: ""
    },
    {
      original: img30,
      thumbnail: ""
    }
  ] 

  
  return (
    <div className="aboutus">
      <div className="image-slider-div">
        <ImageGallery items={images}
          showPlayButton={false}
          showFullscreenButton={false} 
          />
        <h1>
          We Provide The Best Services Around
        </h1>
        <p>
        To see a little bit more about us and what we can accomplish, scroll right or left through the images.
        </p>
        <h2> Including: </h2>
        <ul className="the-ul-services">
          <li>
            LANDSCAPE
          </li>
          <li>
            STONE WALLS
          </li>
          <li>
            STAIRWAYS
          </li>
          <li>
            RETAINING WALLS
          </li>
          <li>
            WALKWAYS
          </li>
          <li>
            PATIOS
          </li>
          <li>
            FALL CLEANUP
          </li>
          <li>
            TREE TRIMMING & REMOVAL
          </li>
        </ul>
      </div>
      <div className="ratings">
        <div className="rating-div">
          <Rating className="rating-part"
            onChange={handleRating}
            initialRating={3}
            emptySymbol={<img src="https://www.svgrepo.com/show/361331/star-empty.svg" className="icon" />}
            fullSymbol={<img src="https://uxwing.com/wp-content/themes/uxwing/download/arts-graphic-shapes/star-full-icon.png" className="icon" />}
  />
          <textarea
            value={message}
            onChange={handleMessageChange}
            placeholder="Type your message here..."
            rows={4} 
            cols={50} 
          />
          <p>{message.length}</p> 
          <button className="sendbutton" onClick={handleClick}>Send</button>
        </div>
        <div className="ratings-div">
          {Reviews.map(item => (
            <div className="ratings-div-pg">
              <p id="p1" className="ratings-div-p1">{item.stars} Stars Review</p>
              <p id="p2" className="ratings-div-p1">{item.message}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )

  /*
  return (
    <div className="router-provider aboutus">
      <h2>We offer a comprehensive array of services, as outlined in the following list.</h2>
      <ul className="services-ul">
        <li className="landscape">
          <img src={pictures.landscape}></img>
          <h3>Landscape</h3>
          <p>
            We can transform your outdoor space into a beautiful and functional landscape, incorporating elements such as plants, trees, shrubs, and decorative features.
          </p>
        </li>
        <li>
          <img src={pictures.stone_walls}></img>
          <h3>Stone Walls</h3>
          <p>
            We specialize in constructing durable and visually appealing stone walls that enhance the aesthetics and structure of your property.
          </p>
        </li>
        <li>
          <h3>Stairways</h3>
          <p>
            Whether you need simple steps or intricate staircases, we design and build stairways that provide safe and seamless transitions between different levels of your landscape. 
          </p>
        </li>
        <li>
          <img src={pictures.retaining_walls}></img>
          <h3>Retaining Walls</h3>
          <p>
            Our retaining walls are engineered to prevent soil erosion, manage slopes, and create level areas, all while adding visual interest to your landscape.
          </p>
        </li>
        <li>
          <img src={pictures.walkways}></img>
          <h3>Walkways</h3>
          <p>
            We create custom walkways that guide you through your outdoor space with elegance and functionality, using materials such as pavers, flagstone, or concrete.
          </p>
        </li>
        <li>
          <h3>Patios</h3>
          <p>
            Our patio designs offer inviting outdoor living spaces for relaxation and entertainment, constructed with high-quality materials and attention to detail.
          </p>
        </li>
        <li>
          <h3>Fall Cleanup</h3>
          <p>
            As the seasons change, we provide thorough fall cleanup services to remove leaves, debris, and other clutter from your landscape, ensuring a clean and tidy appearance.
          </p>
        </li>
        <li>
          <h3>Tree Trimming & Removal</h3>
          <p>
            Our skilled arborists perform precise tree trimming to promote healthy growth, enhance tree aesthetics, and maintain safety by removing dead or overhanging branches.

            When necessary, we safely and efficiently remove trees that pose risks to your property or are no longer viable, using industry-standard techniques and equipment.
          </p>
        </li>
      </ul>
    </div>
  ) */
}

export default AboutUs;
