import React from "react"
import "./footer.css"

function Footer(){
    return (
        <div className="footer-pg">
            <section id="copy-right">
                <div class="copy-right-sec"><i class="fa-solid fa-copyright"></i>  
                    VIP Construction & Irrigation - All Rights Reserved 2024 <br></br> Developed By <a target="_blank" href="https://mail.google.com/mail/?view=cm&fs=1&to=thallysonmma@gmail.com">Thallyson</a> 
                    <a href="https://github.com/KidThallyson" target="_blank"><img src="https://cdn-icons-png.flaticon.com/512/25/25231.png" width={20} height={20}></img></a>
                </div>

            </section>
        </div>
        
)}

export default Footer;
