import React from "react"
import ReactDOM from "react-dom/client"
import "./home.css"
import vipImg from './resources/vip.png'
import mainImg from './resources/main.png'

function Home(){
  const site_name = "VIP Construction Irrigation"
  const site_welcome = "Hello customer, "
  const site_desc = "Transforming landscapes and communities through precision construction and sustainable irrigation solutions, paving the way for a greener and beautiful future."
  return (
    <div className="router-provider home">
      <div className="site-info0">
         <img src={mainImg}></img>
      </div>
      <div className="site-info">
        <h1>{site_name}</h1>
        <h2>{site_welcome}</h2>
        <p>{site_desc}</p>
      </div>
    </div>
  )
}

export default Home;
