import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import CHeader from './customs/cheader.js';
import Home from './customs/pages/home.js';
import AboutUs from './customs/pages/aboutus.js';
import Contact from './customs/pages/contact.js';
import Footer from "./customs/footer.js"

function App() {
  return (
    <div className="App">
      <Router>
        <CHeader />
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/aboutus" element={<AboutUs/>} />
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
