import React from "react"
import ReactDOM from "react-dom/client"
import "./cheader.css"
import { Link, Outlet } from "react-router-dom";

function CHeader(){


  const pages = [
    { name: "Home", link: "/" },
    { name: "Contact", link: "/contact" },
    { name: "About Us", link: "/aboutus" },
  ];
  const list_items = pages.map((element) => 
    <li class="ul-elements">
      <Link to={element.link}>
      {element.name}
      </Link>
    
    </li>
  );
  return (
    <div className="CHeader">
      <ul className="ul-main-elements">
        {list_items}
        
      </ul>
    </div>
  )
}

export default CHeader;


